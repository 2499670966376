import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import washer from "../images/washer.png"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`launderette`, `commercial`, `north-east`, `domestic`, `cleaning`, `laundry`, `north yorkshire`, `darlington`, `collection`, `drop-off`]} />
      <div class="jumbotron jumbotron-fluid text-light mb-0 bg-gradient">
        <div class="container">
          <div class="bg-image text-center text-xl-left">
            <h1 class="display-4 mb-2 mb-md-5">Welcome to Church Street Launderette</h1>
            <p class="lead">We are a well established, professional launderette, servicing both domestic &amp; commercial customers.</p>
            <p class="lead">
              If you have any questions about the services we provide, please don't hesitate to <a class="bold" href="tel:07732238973">call</a> or <a class="bold" href="mailto:info@cs-launderette.co.uk">email</a> and we will do our best to help in any way we can.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-light">
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-12 col-md-6 text-center">
              <img class="img-fluid logo" alt="Washing machine" src={washer} />
            </div>
            <div class="col-12 col-md-6">
              <h2 class="display-5 mb-1 mb-md-5 mb-5">We deliver on our promises</h2>
              <p>With over 30 years experience in the launderette industry, we have built up a strong clientelle over the years.</p>
              <p>We are a family run business and pride ourselves on providing a great, reliable service at a great price and in a timely manner. We are based in Shildon, County Durham and provide a pick up and drop off service to the local area such as Darlington, Bishop Auckland, Newton Aycliffe and surrounding areas.</p>
              <p>Get in touch and see how we can help you.</p>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="bg-baby text-dark">
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-12 col-sm-4 text-center mb-3 mb-sm-0">
              <i class="fa fa-gbp fa-5x"></i>
              <p class="mt-4">We offer three levels of pricing so we can be sure to offer you the best value for your needs.</p>
            </div>
            <div class="col-12 col-sm-4 text-center mb-3 mb-sm-0">
              <i class="fa fa-truck fa-5x"></i>
              <p class="mt-4">Collection and drop off service available to addresses within the catchment area.</p>
            </div>
            <div class="col-12 col-sm-4 text-center mb-3 mb-sm-0">
              <i class="fa fa-moon-o fa-5x"></i>
              <p class="mt-4">Call us anytime, day or night, as we are always happy to help</p>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div id="location" class="bg-light">
        <div class="container">
          <div class="row pt-5 pb-5">
            <div class="col-12 col-md-6 mb-5">
              <h3 class="mb-1 mb-md-5 mb-5">Find us</h3>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2309.289945034632!2d-1.6445270565565009!3d54.63411236699882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e830457a8a647%3A0x843e1ccbab109bb6!2sChurch+Street+Launderette!5e0!3m2!1sen!2suk!4v1563528355336!5m2!1sen!2suk" height="300" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="col-12 col-md-6">
              <h3 class="mb-1 mb-md-5 mb-5">Opening Hours</h3>
              <div class="mb-1">
                <div class="clearfix"><span class="float-left bold">Monday</span><span class="float-right">10.00am - 4.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Tuesday</span><span class="float-right">10.00am - 4.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Wednesday</span><span class="float-right">10.00am - 4.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Thursday</span><span class="float-right">10.00am - 4.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Friday</span><span class="float-right">10.00am - 4.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Saturday</span><span class="float-right">10.00am - 3.00pm</span></div>
                <div class="clearfix"><span class="float-left bold">Sunday</span><span class="float-right bold">Closed</span></div>
              </div>
              <p>You can call us out of hours on <a href="tel:07732238973">07732 238973</a></p>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div id="services" class="bg-cornflower">
        <div class="container">
          <div class="row pt-5 pb-5">
            <div class="col-12">
              <h2 class="mb-1 mb-md-5 mb-5">Services</h2>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <h4 class="mb-3">Clothing</h4>
              <div class="row">
                <div class="col-6">Shirts (hanging)</div>
                <div class="col-6">Leggings</div>
                <div class="col-6">T-shirts</div>
                <div class="col-6">Pyjamas</div>
                <div class="col-6">Jumpers</div>
                <div class="col-6">Dressing Gowns</div>
                <div class="col-6">Trousers</div>
                <div class="col-6">Underwear</div>
                <div class="col-6">Jeans - Denim</div>
                <div class="col-6">Socks</div>
                <div class="col-6">Tracksuits</div>
                <div class="col-6">Hats</div>
                <div class="col-6">Blouses</div>
                <div class="col-6">Gloves</div>
                <div class="col-6">Dresses</div>
                <div class="col-6">Scarves</div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <h4 class="mb-3">Bedding</h4>
              <div class="row mb-3">
                <div class="col-6">Bedspreads</div>
                <div class="col-6">Pillows</div>
                <div class="col-6">Blankets</div>
                <div class="col-6">Pillowcases</div>
                <div class="col-6">Duvet Covers</div>
                <div class="col-6">Sheets</div>
                <div class="col-6">Duvets</div>
                <div class="col-6">Valances</div>
              </div>
              <h4 class="mb-3">Upholstery</h4>
              <div class="row">
                <div class="col-6">Curtains</div>
                <div class="col-6">Cushion Covers</div>
                <div class="col-6">Curtain Tie-backs</div>
                <div class="col-6">Settee Covers</div>
                <div class="col-6">Cushions</div>
                <div class="col-6">Throws</div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <h4 class="mb-3">Bathroom &amp; Kitchen</h4>
              <div class="row">
                <div class="col-6">Tableclothes</div>
                <div class="col-6">Bath Towels</div>
                <div class="col-6">Tea Towels</div>
                <div class="col-6">Bath Mats</div>
                <div class="col-6">Hand Towels</div>
                <div class="col-6">Shower Curtains</div>
              </div>
            </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
      <div id="prices" class="bg-light">
      <div class="container">
        <div class="row pt-5 pb-5">
          <div class="col-12">
            <h2 class="mb-1 mb-md-5 mb-5">Pricing</h2>
          </div>
          <div class="col-12 col-md-4 mb-4">
            <h4>Wash, Dry &amp; Fold</h4>
            <div class="bold">
              <div class="clearfix"><div class="float-left">Small Wash (5.5kg)</div><div class="float-right text-gold">&pound;5.50</div></div>
              <div class="clearfix"><div class="float-left">Medium Wash (9kg)</div><div class="float-right text-gold">&pound;7.50</div></div>
              <div class="clearfix"><div class="float-left">Large Wash (13kg)</div><div class="float-right text-gold">&pound;10</div></div>
              <div class="clearfix"><div class="float-left">X Large Wash (18kg)</div><div class="float-right text-gold">&pound;17</div></div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4">
            <h4>Duvets</h4>
            <div class="bold">
              <div class="clearfix"><div class="float-left">Single</div><div class="float-right text-gold">&pound;6</div></div>
              <div class="clearfix"><div class="float-left">Double</div><div class="float-right text-gold">&pound;8</div></div>
              <div class="clearfix"><div class="float-left">King</div><div class="float-right text-gold">&pound;10</div></div>
              <div class="clearfix"><div class="float-left">Super King</div><div class="float-right text-gold">&pound;12</div></div>
            </div>
            <p>+ £2.50 duck down</p>
          </div>
          <div class="col-12 col-md-4 mb-4">
            <h4>Ironing</h4>
            <div>
              <div class="clearfix">
                <div class="float-left">
                  <div class="bold">Clothes</div>
                  <small>Hung or folded</small></div>
                <div class="float-right text-gold bold">&pound;3.10 per kg</div>
              </div>
              <div class="clearfix">
                <div class="float-left">
                  <div class="bold">Bedding</div>
                  <small>Pressed</small>
                </div>
                <div class="float-right text-gold bold">&pound;1.90 per kg</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <h4>Rugs from &pound;10</h4>
            <h6>Prices for items not listed on request</h6>
            <h6>Commercial prices available - please get in touch for a quote</h6>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default IndexPage
